import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "graphql-hooks";
import dayjs from "dayjs";
import {
  Event as QueryEvent,
  Equipments as QueryEquipments,
} from "../graphql/queries";
import { EventRegistration } from "../graphql/mutations";
import Placeholder from "../components/Placeholder";
import Card from "../components/Card";
import EquipmentSelector from "../components/EquipmentSelector";
import { Button, Notification, Alert } from "../components/ui";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons/faTimesCircle";

import euro2019 from "../images/events/euro2019.jpg";
import euro2022 from "../images/events/euro2022.jpg";
import words2019 from "../images/events/world2019.jpg";
import uk2019 from "../images/events/uk2019.jpg";
import uk2020 from "../images/events/uk2020.jpg";

const ukImages = {
  2019: uk2019,
  2020: uk2020,
};

const eventImages = {
  GSpufppFXyPAhPgRyjW8Q: euro2022,
};

const EventCard = ({
  id,
  name,
  startDate,
  image,
  endDate,
  expired,
  organization,
  location,
  description,
  contact,
  season,
}) => (
  <Card className="bg-white">
    <div className="">
      <img
        className="w-full rounded-t"
        src={
          eventImages[id]
            ? eventImages[id]
            : image
            ? image
            : name.includes("Worlds")
            ? words2019
            : name.includes("Europeans")
            ? euro2019
            : ukImages[season]
        }
        alt={name}
      />
      <div className="flex flex-1 flex-col justify-between items-stretch">
        <div className="">
          <div className="px-4 py-1">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-primary text-grey-lighter"
              }`}
            >
              {dayjs(new Date(parseInt(startDate))).format("DD MMM")} -{" "}
              {dayjs(new Date(parseInt(endDate))).format("DD MMM")}
            </span>
          </div>
          <div className="px-4 py-2">
            <div
              className={`font-bold text-lg mb-2 ${expired ? "text-grey" : ""}`}
            >
              {name}
            </div>
            <p
              className={`text-grey-darker font-light ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {organization}
            </p>
            <p
              className={`text-grey-dark font-light pt-2 ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {description} <br />
              <a
                className="no-underline text-grey-darker hover:text-black"
                href={contact}
              >
                {contact}
              </a>
            </p>
          </div>
          <div className="px-4 py-2">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-secondary text-grey-lighter"
              }`}
            >
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

const Registration = ({ season, id, initialSelectedEquipment }) => {
  const [alert, setAlert] = useState(null);
  const { loading, error, data } = useQuery(QueryEquipments, {
    variables: { type: "all" },
  });
  const [
    register,
    { loading: saving, error: errorMutation, data: dataMutation },
  ] = useMutation(EventRegistration);
  const equipments = data && data.equipments ? data.equipments : null;
  const [selectedEquipments, setSelectedEquipments] = useState({
    boats:
      initialSelectedEquipment !== null
        ? initialSelectedEquipment.filter((e) => e.split("|")[0] === "hull")
        : [],
    parts:
      initialSelectedEquipment !== null
        ? initialSelectedEquipment.filter((e) => e.split("|")[0] !== "hull")
        : [],
  });
  const changeSelection = (category) => (value) => {
    delete selectedEquipments[category];
    setSelectedEquipments({ ...selectedEquipments, [category]: value });
  };
  const submit = useCallback(() => {
    const numParts = selectedEquipments["parts"].reduce(
      (count, p) => ({
        ...count,
        [p.split("|")[0]]: count[p.split("|")[0]] + 1,
      }),
      {
        sail: 0,
        mast: 0,
        rudderfoil: 0,
        mainfoil: 0,
      }
    );
    if (selectedEquipments["boats"].length === 0) {
      setAlert("You need to select at least one boat");
    } else if (Object.values(numParts).filter((n) => n > 2).length > 0) {
      setAlert(
        "You can only select 2 sets of equipment (max. 2 pieces per category)"
      );
    } else if (Object.values(numParts).filter((n) => n === 0).length > 0) {
      setAlert(
        "You need to select between 1 and 2 pieces of each equipment (mast, mailfoil, rudderfoil and sail)"
      );
    } else {
      register({
        variables: {
          season,
          id,
          parts: [
            ...selectedEquipments["boats"],
            ...selectedEquipments["parts"],
          ],
        },
      });
      setAlert(null);
    }
  }, [selectedEquipments]);
  return (
    <Placeholder
      showMessage={loading || error}
      message={"Loading your equipment"}
    >
      {equipments && (
        <>
          {dataMutation && !saving && !errorMutation && (
            <Notification
              color="green-dark"
              icon={faCheckCircle}
              message={
                initialSelectedEquipment === null
                  ? "You are checked in to this event"
                  : "Your changes were saved"
              }
            />
          )}
          {!saving && errorMutation && (
            <Notification
              color="red-dark"
              icon={faTimesCircle}
              message={
                errorMutation &&
                errorMutation.graphQLErrors &&
                errorMutation.graphQLErrors[0]
                  ? errorMutation.graphQLErrors[0].message
                  : "An error happened and your changes couldn't be saved"
              }
            />
          )}
          {!saving && alert && (
            <Notification
              color="red-dark"
              icon={faTimesCircle}
              message={alert}
            />
          )}
          <Card className="p-2">
            <h3 className="text-grey-darker font-light p-2">
              <strong>Step 1: </strong> Select your boat
            </h3>
            <EquipmentSelector
              onChange={changeSelection("boats")}
              value={selectedEquipments["boats"]}
              multiple={false}
              equipments={equipments.filter((e) => e.type === "hull")}
            />
          </Card>
          <Card className="mt-2 p-2">
            <h3 className="text-grey-darker font-light p-2">
              <strong>Step 2: </strong> Select your parts
            </h3>
            <EquipmentSelector
              onChange={changeSelection("parts")}
              value={selectedEquipments["parts"]}
              equipments={equipments.filter((e) => e.type !== "hull")}
            />
          </Card>
          <Card className="mt-2 px-4">
            <h3 className="text-grey-darker font-light py-2">
              <strong>Step 3: </strong> Confirm Registration
            </h3>
            <p className="text-grey-darker py-4 text-justify">
              Once checked in an event the organisers will have access to your
              list of equipment and will verify your measurement certificates
              using the QR codes that you will receive at the registration desk.
            </p>
            <div>
              <Button
                className="my-2 py-3 px-4 inline-block focus:outline-none"
                onClick={submit}
                disabled={saving}
              >
                {saving
                  ? "Saving..."
                  : initialSelectedEquipment === null
                  ? "Confirm"
                  : "Save Changes"}
              </Button>
            </div>
          </Card>
        </>
      )}
    </Placeholder>
  );
};

export default ({ match }) => {
  const { season, id } = match.params;
  const { loading, error, data } = useQuery(QueryEvent, {
    variables: { season, id },
  });
  return (
    <Placeholder showMessage={loading || error} message={"Loading event info"}>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-2/3 px-2">
          <h2 className="text-black font-light my-4">Equipment Check-in</h2>
          <div className="my-2">
            <Alert message="To comply with IMCA event equipment restrictions and to speed up the measurement process for IMCA measurers you are required to check-in your equipment you intend to use in competition at the event along with any measurement certificates attached to the equipment. This will then be reviewed and approved by the Measurement team at the event." />
          </div>
          {data && data.event && (
            <Registration
              season={data.event.season}
              id={data.event.id}
              initialSelectedEquipment={data.event.userRegistration}
            />
          )}
        </div>
        <div className="w-full px-2 md:w-1/3">
          <h2 className="text-black font-light my-4">Event Info</h2>
          {data && data.event && <EventCard {...data.event} />}
        </div>
      </div>
    </Placeholder>
  );
};
