import isIn from "validator/lib/isIn";
import { default as isEmpty } from "validator/lib/isEmpty";
export { default as isEmail } from "validator/lib/isEmail";
export { default as isNumeric } from "validator/lib/isNumeric";
export { default as isIn } from "validator/lib/isIn";

export function isNotEmpty(value) {
  return !isEmpty(value);
}

export function isInList(list) {
  return value => isIn(value, list);
}

export function minLength(length) {
  return (value) => value.length >= length;
}

export function maxLength(length) {
  return (value) => value.length <= length;
}

export function passwordPolicyApproved(value) {
  return /[0-9]/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value) && /[!@#$%&*()]/.test(value);
}

export function validate(rules) {
  return (value, currentState = null) => {
    for (const rule of rules) {
      const [r, message] = rule;
      if (r(value, currentState) === false) {
        return message;
      }
    }
    return true;
  };
}