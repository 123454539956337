import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm } from "../hooks";
import logo from "../images/logo_white.png";
import { Button, Notification } from "../components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
import { faSnowflake } from "@fortawesome/free-regular-svg-icons/faSnowflake";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { isNotEmpty, isEmail, minLength, validate } from "../utils/validator";

const LoginFormField = ({ label, icon, error, ...props }) => (
  <>
    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-0">
      {label}
    </label>
    <div className="flex flex-row w-full my-2">
      <span
        className={`flex items-center rounded rounded-r-none px-3 font-bold ${
          error
            ? "bg-red-lighter text-red-darker"
            : "bg-grey-lighter text-grey-darker"
        }`}
      >
        <FontAwesomeIcon icon={icon} />
      </span>
      <input
        {...props}
        className="w-full bg-grey-lighter text-grey-darker py-2 font-normal rounded text-grey-darkest border border-grey-lighter rounded-l-none font-bold focus:outline-none"
      />
    </div>
    {error && (
      <span className="block italic text-red-dark text-sm pb-2 text-right">
        {error}
      </span>
    )}
  </>
);

const Form = ({
  mode,
  login,
  signUp,
  facebookLogin,
  universalLogin,
  recoveryPassword,
}) => {
  const [alert, setAlert] = useState([null, null, null]);

  const submit = useCallback(
    async ({ email, password }, errors) => {
      setAlert([null, null, null]);
      if (errors) {
        return false;
      }
      try {
        if (mode === "signin") await login(email, password);
        else if (mode === "signup") await signUp(email, password);
        else if (mode === "recovery-password") {
          await recoveryPassword(email);
          setAlert([
            "green-dark",
            faCheck,
            "If your email is registered in the database you will receive a message shortly to reset your password",
          ]);
        }
      } catch (e) {
        setAlert(["red", faExclamation, e.toString()]);
      }
      return false;
    },
    [mode]
  );

  const validation = {
    email: validate([
      [isNotEmpty, "Please inform your email"],
      [isEmail, "Please inform a valid email"],
    ]),
  };
  if (mode !== "recovery-password") {
    validation["password"] = validate([
      [isNotEmpty, "Please inform your password"],
      [minLength(8), "Your password must have at least 8 characters"],
    ]);
  }
  const { values, handleChange, handleSubmit, errors } = useForm(
    submit,
    validation,
    { email: "", password: "" },
    false,
    null,
    `form-${mode}`
  );
  return (
    <div className="py-12 px-6">
      <div className="w-full">
        <div className="flex items-center justify-between my-4">
          <Button
            className="block leading-loose tracking-wide w-full text-md lg:text-base"
            color="facebook"
            onClick={facebookLogin}
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              className="text-white mr-4"
              style={{ transform: "scale(1.6)" }}
            />{" "}
            Sign in with Facebook
          </Button>
        </div>

        <ul className="list-reset flex w-full">
          <li className="flex-1">
            <Link
              to="/login"
              className={`text-center uppercase font-bold no-underline block border-b-4 py-2 ${
                mode === "signin"
                  ? "border-primary text-primary"
                  : "text-grey-dark border-transparent hover:border-grey hover:bg-grey-lightest hover:text-black"
              }`}
            >
              Sign In
            </Link>
          </li>
          <li className="flex-1">
            <Link
              to="/create-account"
              className={`text-center uppercase font-bold no-underline block border-b-4 py-2 ${
                mode === "signup"
                  ? "border-primary text-primary"
                  : "text-grey-dark border-transparent hover:border-grey hover:bg-grey-lightest hover:text-black"
              }`}
            >
              Sign Up
            </Link>
          </li>
        </ul>

        <form
          className="w-full mb-4 form-input border-t border-grey-light pt-4"
          onSubmit={handleSubmit}
        >
          {alert[0] !== null && (
            <div className="text-sm">
              <Notification
                color={alert[0]}
                icon={alert[1]}
                message={alert[2]}
              />
            </div>
          )}
          <LoginFormField
            label="E-mail"
            icon={faEnvelope}
            error={errors["email"]}
            id="email"
            name="email"
            type="email"
            placeholder="E-MAIL ADDRESS"
            value={values.email || ""}
            onChange={handleChange}
          />
          {mode !== "recovery-password" && (
            <LoginFormField
              label="Password"
              icon={faSnowflake}
              error={errors["password"]}
              id="password"
              name="password"
              type="password"
              placeholder="PASSWORD"
              value={values.password || ""}
              onChange={handleChange}
            />
          )}
          <div className="flex items-center justify-between mt-8">
            <Button
              className="block tracking-wide leading-normal w-full text-md lg:text-lg"
              type="submit"
            >
              {mode === "signin"
                ? "Sign In"
                : mode === "signup"
                ? "Sign Up"
                : "Recovery Password"}
            </Button>
          </div>
          {mode === "signin" && (
            <div className="flex items-center justify-center text-center mt-2">
              <Link
                to="/recovery-password"
                className="no-underline font-light text-grey-dark"
              >
                Forgot your password?
              </Link>
            </div>
          )}
          {mode !== "recovery-password" && (
            <div className="flex items-center justify-center text-center mt-2">
              <Link
                to="/terms"
                className="no-underline font-light text-grey-dark"
              >
                Terms of Service
              </Link>
              <span className="px-1 text-grey">{"and"}</span>
              <Link
                to="/privacy"
                className="no-underline font-light text-grey-dark"
              >
                Privacy Policy
              </Link>
            </div>
          )}
          {mode === "recovery-password" && (
            <div className="flex items-center justify-center text-center mt-2">
              <Link
                to="/login"
                className="no-underline font-light text-grey-dark"
              >
                Back to Login
              </Link>
            </div>
          )}
          <div className="flex items-center justify-between my-4">
            <Button
              className="block leading-loose tracking-wide w-full text-md lg:text-base"
              color=""
              onClick={universalLogin}
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                className="text-white mr-4"
                style={{ transform: "scale(1.6)" }}
              />{" "}
              Safari Login
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ({
  mode,
  signUp,
  login,
  facebookLogin,
  universalLogin,
  recoveryPassword,
}) => {
  return (
    <div className="w-full min-h-screen flex bg-grey-light font-sans">
      <div className="fixed bg-primary w-full z-0" style={{ height: "70vh" }}>
        {}
      </div>
      <div className="flex w-full h-100 justify-center items-center z-10">
        <div className="w-full m-8 max-w-sm md:max-w-lg lg:max-w-lg xl:max-w-xl h-100 rounded-lg shadow-lg flex flex-col md:flex-row bg-white">
          <div className="flex w-full md:w-3/5 bg-black text-white rounded-t-lg md:rounded-r-none md:rounded-l-lg flex-col content-center justify-center">
            <div className="p-8">
              <div className="mb-4">
                <img src={logo} alt="" style={{ maxWidth: "300px" }} />
                <h2 className="my-3 font-serif font-light text-xl lg:text-2xl">
                  Welcome to the Moth Class Certificates and Measurement
                  Database
                </h2>
                <p className="hidden sm:block lg:text-lg">
                  You can use the database to register and manage your equipment
                  for IMCA international events
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:w-2/5 flex flex-col content-center justify-center">
            <Form
              login={login}
              signUp={signUp}
              facebookLogin={facebookLogin}
              universalLogin={universalLogin}
              recoveryPassword={recoveryPassword}
              mode={mode}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
