import React from 'react';
import Card from '../components/Card';

export default () => {

  return (
    <Card className="p-4">
      <h2 className='text-black font-light my-4'>FAQ</h2>
      <p className="py-4">
      <strong>I need to update my insurance certificate. How do I do this?</strong><br />
      A: Simply select Remove Certificate and upload your new certificate.
      </p>
      <p className="py-4">
      <strong>I have broken or sold a piece of equipment what should I do?</strong><br />
      A: Please use the deactivate button and add some text explaining your reason for removing the part.
      ‌</p>
      <p className="py-4">
      <strong>There is a manufacturer / model not selectable in the dropdowns. What should I do?</strong><br />
      A: Fear not - we periodically update the dropdowns - just enter the manufacturer / model in the text box using the Not Listed option and we will add this as an option in the future.
      ‌</p>
      <p className="py-4">
      <strong>I want to amend what I have checked in for an event, how do I do this?</strong><br />
      A: Provided you are updating prior to the cutoff point you are able to make as many changes as you like to checked in equipment.
      </p>
    </Card>
  );
}
