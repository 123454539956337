export const CreateEquipment = `mutation CreateEquipment($input: CreateEquipmentInput!) {
  createEquipment(input: $input) {
    userId
    typeId
  }
}
`;

export const UpdateEquipment = `mutation UpdateEquipment($type: String!, $id: String!,$input: UpdateEquipmentInput!) {
  updateEquipment(type: $type, id: $id, input: $input) {
    userId
    typeId
  }
}
`;

export const UpdateProfile = `mutation UpdateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    name
    email
    homeClub
    isafId
    country
  }
}
`;

export const GenerateEquipmentCertificateUploadLink = `mutation GenerateEquipmentCertificateUploadLink($type: String!, $id: String!, $filetype: String!, $number: Int!) {
  generateEquipmentCertificateUploadLink(type: $type, id: $id, filetype: $filetype, number: $number) {
    url
    expiresIn
  }
}
`;

export const GenerateEquipmentInsuranceUploadLink = `mutation GenerateEquipmentInsuranceUploadLink($type: String!, $id: String!, $filetype: String!) {
  generateEquipmentInsuranceUploadLink(type: $type, id: $id, filetype: $filetype) {
    url
    expiresIn
  }
}
`;

export const EventRegistration = `mutation EventRegistration($season: String!, $id: String!, $parts: [String!]!) {
  register(season: $season, id: $id, parts: $parts) {
    season
    id
  }
}
`;

export const RemoveCertificate = `mutation RemoveCertificate($type: String!, $id: String!) {
  removeEquipmentCertificate(type: $type, id: $id)
}
`;

export const RemoveInsurance = `mutation RemoveInsurance($type: String!, $id: String!) {
  removeEquipmentInsurance(type: $type, id: $id)
}
`;
export const DeactivateEquipment = `mutation DeactivateEquipment($type: String!, $id: String!, $reason: String) {
  deactivateEquipment(type: $type, id: $id, reason: $reason)
}
`;

export const AddPartToManufacturer = `mutation AddPartToManufacturer($manufacturerId: ID!, $type: String!, $input: PartModelInput) {
  addPartToManufacturer(manufacturerId: $manufacturerId, type: $type, input: $input) {
    name
  }
}
`;