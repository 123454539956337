import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo_white.png';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';

export default ({ userProfile, logout }) => (
  <div className='w-full h-12 shadow bg-secondary text-white flex justify-between'>
    <div className='flex justify-start'>
      <ul className='flex items-center list-reset'>
        <li>
          <Link to='/'>
            <img src={logo} alt='Moth Class' className='max-h-auto h-10 m-1 ml-4 max-w-full' />
          </Link>
        </li>
        <li>
          <span className="bg-primary rounded-full mr-2 py-1 px-4 font-bold">
            BETA
          </span>
        </li>
        <li className="h-full flex">
          <Link to="/" className="text-blue-lighter flex justify-center items-center no-underline uppercase font-bold flex-1 h-full px-2 hover:bg-primary hover:text-white">
            Home
          </Link>
        </li>
        <li className="h-full flex">
          <Link to="/faq" className="text-blue-lighter flex justify-center items-center no-underline uppercase font-bold flex-1 h-full px-2 hover:bg-primary hover:text-white">
            FAQ
          </Link>
        </li>
      </ul>
    </div>
    <div className='flex justify-end'>
      { userProfile && (
        <ul className='flex items-center list-reset'>
        <li>
          <Link to='/profile' className='cursor-pointer flex justify-center items-center text-grey-light border-grey-light border opacity-50 hover:opacity-100 border-dashed h-10 w-10 mr-2 rounded-full bg-blue-dark text-lg focus:outline-none'>
            <FontAwesomeIcon icon={faUser} />
          </Link>
        </li>
        <li>
          <button onClick={() => logout()} className='cursor-pointer text-grey-light border-grey-light border opacity-50 hover:opacity-100 border-dashed h-10 w-10 mr-4 rounded-full bg-blue-dark text-lg focus:outline-none'>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </li>
      </ul>
      )}
    </div>
  </div>
);