import React, { useState } from 'react';
import { useForm } from '../../hooks';
import { isNotEmpty, validate } from "../../utils/validator";
import { Button, Alert } from "../ui";

export default ({ initialData = {}, onSubmit, lock = false, alert = null }) => {
  const [message, setMessage] = useState([null, null, null]);
  const validation = {
    "eventCode": validate([[isNotEmpty, "Please inform the event organiser PIN"]]),
    "equipmentCode": validate([[isNotEmpty, "Invalid equipment code"]]),
  };
  const submit = async (values, errors) => {
    if (errors === false) {
      if (onSubmit) {
        try {
          const variables = Object.keys(values).reduce((input, prop) => values[prop] ? ({ [prop]: values[prop], ...input }) : input, {});
          await onSubmit(variables);
        } catch (e) {
          setMessage(["Please verify the errors below" ,e.errors.map(e => e.message).join(", "), "error"]);
        }
      }
    } else {
      setMessage(["Please verify the errors below", null, "error"]);
    }
    return false;
  };
  const { eventCode = "", equipmentCode } = initialData;
  const { handleSubmit, render } = useForm(submit, validation, { eventCode, equipmentCode }, [
    {
      title: "Event Information",
      subtitle: "Please inform the unique code for your event",
      fields: [
        ["eventCode", "Event Code", "text", "p-1 w-full"],
      ]
    }
  ]);
  return (
    <form className='w-full' onSubmit={handleSubmit}>
      { message[0] ? (<Alert type={message[2]} title={message[0]} message={message[1]} />) : (alert && (<Alert type={alert[2]} title={alert[0]} message={alert[1]} />)) }
      {render}
      <div className='flex justify-end'>
        <Button type='submit' className='mr-4' disabled={lock}>{lock ? "Processing..." : "Submit"}</Button>
      </div>
    </form>
  );
};
