import React, { useState, useCallback } from 'react'
import Card from "../../components/Card";
import { ListManufacturers } from "../../graphql/queries"
import { AddPartToManufacturer } from "../../graphql/mutations";
import { useQuery, useMutation } from "graphql-hooks"
import Placeholder from "../../components/Placeholder";
import CreateEquipmentForm from "../../components/forms/CreateEquipment";
import { Button } from "../../components/ui";

const colors = {
  "hull": "green",
  "sail": "yellow-dark",
  "mainfoil": "purple",
  "rudderfoil": "pink-dark"
};


const ManufacturerPart = ({ type, name, length, span, notes }) => (
  <div className="flex-initial flex w-100 overflow-hidden md:max-w-1/3 md:w-1/3">
    <div className="rounded m-2 flex-grow-1 flex flex-col w-full" style={{ boxShadow: '0px 2px 3px #C1C7D0' }}>
      <div className='flex flex-1 flex-col justify-between items-stretch'>
        <div className=''>
          <div className="px-2 py-1">
            <span className={`inline-block uppercase rounded-full px-3 py-1 text-xs font-semibold mr-2 text-grey-lighter bg-${colors[type] || 'blue-darker'}`}>
              {type}
            </span>
          </div>
          <div className="px-2 py-2">
            <div className={`font-bold text-base mb-2 `}>{name}</div>
            <p className={`text-grey-darker font-light text-sm ${type === "hull" ? '' : ''}`}>
              <strong>{length && `L: ${length}`}</strong>
              <strong>{span && ` S: ${span}`}</strong>
              {notes && (<><br />{notes}</>)}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ManufacturerManager = ({ manufacturer, refetch }) => {
  const { id, name, parts } = manufacturer;
  const [showForm, setShowForm] = useState(false);
  const [addPart, { loading: loadingMutation }] = useMutation(AddPartToManufacturer);
  const allParts = parts.reduce((acc, group) => [...acc, ...group.models.map(p => ({...p, type: group.type}))], []);
  const submit = useCallback(async ({ type, ...fields }) => {
    await addPart({ variables: { type, manufacturerId: id, input: { ...fields } } });
    if (refetch) refetch();
  }, [id, manufacturer.id, refetch]);

  return (
    <>
      <h2 className="text-black font-light my-4">{name}</h2>
      <Card className="p-2 mb-2">
        <div className='flex flex-1 flex-wrap md:px-0 justify-left'>
          {allParts.map((p, i) => (<ManufacturerPart key={`${id}-${p.type}-${i}`} {...p}  />))}
        </div>
        {!showForm && (<Button color="primary" onClick={() => setShowForm(true)}  className='self-end mr-4'>Add Equipment</Button>)}
        {showForm && (<CreateEquipmentForm manufacturer={id} onSubmit={submit} lock={loadingMutation} />)}
      </Card>
    </>
  )
}

export default () => {
  const { loading, error, data, refetch } = useQuery(ListManufacturers);
  return (
    <Placeholder showMessage={loading || error} message={"Loading manufacturers"}>
      { data && data.manufacturers && data.manufacturers.map((m, i) => <ManufacturerManager manufacturer={m} key={`manufact-${i}`} refetch={refetch} />)}
    </Placeholder>
  )
};

