import React from 'react';
import { Route } from "react-router-dom";
import Header from "../components/Header";

export default ({ page: Page, active, userProfile, ...rest}) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <div className='bg-grey-lighter min-h-screen font-sans'>
          <Header userProfile={userProfile} {...rest} />
          <div className="flex flex-col flex-1 md:justify-center w-full max-w-xl mx-auto p-1 pt-4">
            <Page {...matchProps} {...rest} userProfile={userProfile} />
          </div>
        </div>
      )}
    />
  );
};