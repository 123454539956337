import React, { useCallback } from 'react';
import { useQuery, useMutation } from 'graphql-hooks';
import { Profile as QueryProfile } from '../graphql/queries';
import { UpdateProfile } from '../graphql/mutations';
import Placeholder from "../components/Placeholder";
import Card from '../components/Card';
import ProfileForm from "../components/forms/ProfileForm";

export default ({ onUpdate }) => {
  const { loading, error, data, refetch } = useQuery(QueryProfile);
  const [updateProfile, { loading: loadingMutation }] = useMutation(UpdateProfile);
  const submit = useCallback(async variables => {
    await updateProfile({ variables });
    if (refetch) refetch();
    if (onUpdate) onUpdate();
  }, [loading]);
  

  return (
    <Card className="p-4">
      <h2 className='text-black font-light my-4'>Your Profile</h2>
      <Placeholder showMessage={(loading && !data) || error} message={"Loading profile"}>
        <ProfileForm 
          initialData={data && data.profile ? data.profile : {}} 
          lock={loadingMutation} 
          onSubmit={submit} 
        />
      </Placeholder>
    </Card>
  );
}
