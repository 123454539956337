import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default ({ icon, message, color }) => (
  <div className="pb-2">
    <div className={`p-2 bg-grey-light items-center text-${color} leading-none lg:rounded-full flex lg:inline-flex`} role="alert">
      <span className={`flex rounded-full bg-${color} text-grey-lighter uppercase px-2 py-1 text-xs font-light mr-3`}><FontAwesomeIcon icon={icon} /></span>
      <span className="font-light mr-2 text-left flex-auto">{message}</span>
    </div>
  </div>
);
