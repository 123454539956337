import React from "react";

const Option = ({ toggleSelection, selected, name, typeId, manufacturer, model, type, plaque, year }) => (
  <div className="flex overflow-hidden w-1/3 md:max-w-1/4 md:w-1/4">
    <div 
      onClick={(e) => { e.stopPropagation(); toggleSelection(typeId) }} 
      className={`group rounded m-2 cursor-pointer flex-grow-1 flex flex-col w-full border-2 ${selected ? 'border-solid border-primary bg-primary text-white' : 'border-dashed bg-grey-lighter hover:bg-grey-light hover:text-secondary'} hover:border-solid hover:border-secondary`}>
      <div className='flex flex-1 flex-col justify-between items-stretch'>
        <div className=''>
          <div className="px-2 py-1">
            <span className={`block text-center uppercase rounded-full px-3 py-1 text-xs text-grey-lighter ${type === "hull" ? 'bg-secondary' : 'bg-primary'}`}>
              {type === "hull" ? `${plaque.country} ${plaque.number}` : type}
            </span>
          </div>
          <div className="px-2 py-2">
            <div className={`text-base mb-2 ${type === "hull" ? '' : ''}`}>{name}</div>
            <p className={`${selected ? 'text-grey-lighter' : 'text-grey-darker'} font-light text-sm ${type === "hull" ? '' : ''}`}>
              <strong>{manufacturer}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ({ equipments, multiple = true, value, onChange }) => {
  const toggleSelection = (option) => {
    if (multiple)
      onChange(value.includes(option) ? value.filter(s => s !== option) : [...value, option]);
    else 
      onChange(value.includes(option) ? [] : [option]);
  }
  return (
    <div className='flex w-full flex-wrap'>
      { equipments.map((e,i) => (<Option selected={value.includes(e.typeId)} toggleSelection={toggleSelection} key={`equipment-${e.type}-${i}`} {...e} />))}
    </div>
  );
};