import React, { useEffect } from "react";
import { useQuery } from "graphql-hooks";
import QRCode from "qrcode.react";
import { EventManagement as QueryEvent } from "../graphql/queries";
import Placeholder from "../components/Placeholder";
import Card from "../components/Card";

const EquipmentLabel = ({ name, country, plaque, part }) => (
  <div className="">
    {/* <div className='pagebreak hidden' style={{height: "0px"}}></div> */}
    <div
      className="flex flex justify-center items-center overflow-hidden border-0 border-black"
      style={{
        height: "58.25mm",
        width: "102mm",
        pageBreakAfter: "always",
        pageBreakInside: "avoid",
        breakAfter: "always",
        breakInside: "avoid",
      }}
    >
      <span
        className="text-xs text-right font-bold uppercase text-black mx-2"
        style={{ transform: "rotate(90deg)" }}
      >
        {name} <br />
        {plaque ? `${plaque.country} ${plaque.number}` : country}
      </span>
      <div className="p-1">
        <QRCode value={part.qrcode} size={180} />
      </div>
      <span
        className="text-xs font-bold uppercase text-black px-2"
        style={{ transform: "rotate(-90deg) scale(0.8)" }}
      >
        {part.type}
        <br />
        {part.name}
      </span>
    </div>
  </div>
);

const ParticipantLabels = ({ id, name, country, parts }) => {
  const plaque = parts.find((p) => p.type === "hull")?.plaque;
  return (
    <div className="relative">
      {parts.map((part, j) =>
        part.type === "mast" ? (
          <>
            <EquipmentLabel
              name={name}
              country={country}
              part={part}
              plaque={plaque}
              key={`participant-${id}-${j}-1`}
            />
            <EquipmentLabel
              name={name}
              country={country}
              part={part}
              plaque={plaque}
              key={`participant-${id}-${j}-2`}
            />
          </>
        ) : (
          <EquipmentLabel
            name={name}
            country={country}
            part={part}
            plaque={plaque}
            key={`participant-${id}-${j}`}
          />
        )
      )}
    </div>
  );
};

const EventLabels = ({ participants, id, season }) => (
  <Card className="bg-white">
    {participants.map((p, i) => (
      <ParticipantLabels
        key={`participant-${id}-${i}`}
        {...p}
        id={id}
        season={season}
      />
    ))}
  </Card>
);

export default ({ match }) => {
  const { season, id, participantId = null } = match.params;
  const { loading, error, data } = useQuery(QueryEvent, {
    variables: { season, id, participantId },
  });
  useEffect(() => {
    if (loading === false && data) {
      window.print();
    }
  }, [loading, data]);
  return (
    <Placeholder showMessage={loading || error} message={"Processing..."}>
      <div className="flex flex-col-reverse">
        <div className="w-full">
          {data && data.event && (
            <EventLabels
              participants={data.event.participants}
              season={data.event.season}
              id={data.event.id}
            />
          )}
        </div>
      </div>
    </Placeholder>
  );
};
