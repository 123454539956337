import React from 'react';
import Card from './Card';
import Button from "./ui/Button";

export default () => {

  return (
    <Card className="p-4">
      <h2 className='text-black font-light my-4'>Welcome to the Moth Class Measurement System!</h2>
      <p className="py-4">
      The Moth Class measurement system is available for all global members of the International Moth Class to use to manage their equipment and measurement certificates for events. This system was developed by IMCA with the objective of:
      </p>
      <div>
      <ul>
        <li>Accelerating the measurement process</li>
        <li>Providing a secure, electronic storage solution for sailors to store insurance and boat measurement documents for using at events</li>
        <li>Providing a more efficient method for measurers to manage requirements during events and to ensure equipment usage rules are complied with</li>
      </ul>
      </div>
      <p className="py-2">
      <strong>How do I start?</strong><br />
      To start using the measurement system you must first create a Hull and populate your ISAF plaque number and other information. You will be able to upload your hull measurement and insurance certificates here. Once completed you can add parts such as sails, mainfoils, rudders and masts. There is no limit to how much equipment you can add to your profile. For sails you are able to also upload your measurement certificates to expedite the check-in process at events.
      ‌<br />
      </p>
      <div className="py-2 flex justify-center">
      <Button link to="/equipment/boat/add">Create your first Boat!</Button>
      </div>
      <p className="py-2">
      Once your equipment is uploaded you are able to check-in equipment to upcoming events using the system in compliance with the Notice of Race (NOR) restrictions of the particular championship. Each championship page lists a cutoff date &amp; time by which all equipment you intend to race with needs to be checked in for measurement review. You are required to input some basic dimensions for equipment in order to ensure the equipment you are using at the event is the equipment you have checked into the measurement system.
      ‌</p>
      <p className="py-2">
      During the event check-in process at the championship you have entered you will be asked to confirm the equipment you wish to you use. You will then be issued with a small, waterproof QR code sticker to attach to your equipment (e.g. the top of your foils) in order to make it efficient for measurers to ensure compliance with measurement rules.
      </p>
      <p className="py-2">
      <strong>Contact Us</strong><br />
      Please note that this is a beta system and we are actively interested in feedback in order to improve workflow and other aspects. To raise requests / provide feedback please contact the system administrators at
      ‌</p>
    </Card>
  );
}
