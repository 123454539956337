import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "graphql-hooks";
import dayjs from "dayjs";
import { EventManagement as QueryEvent } from "../graphql/queries";
import Placeholder from "../components/Placeholder";
import Card from "../components/Card";
// import { Button } from "../components/ui";

import euro2019 from "../images/events/euro2019.jpg";
import euro2022 from "../images/events/euro2022.jpg";
import words2019 from "../images/events/world2019.jpg";
import uk2019 from "../images/events/uk2019.jpg";
import uk2020 from "../images/events/uk2020.jpg";

const ukImages = {
  2019: uk2019,
  2020: uk2020,
};

const eventImages = {
  GSpufppFXyPAhPgRyjW8Q: euro2022,
};

const colors = {
  hull: "green",
  sail: "yellow-dark",
  mainfoil: "purple",
  rudderfoil: "pink-dark",
};

const EventCard = ({
  id,
  name,
  startDate,
  endDate,
  image,
  expired,
  organization,
  location,
  description,
  season,
  contact,
}) => (
  <Card className="bg-white">
    <div className="">
      <img
        className="w-full rounded-t"
        src={
          eventImages[id]
            ? eventImages[id]
            : image
            ? image
            : name.includes("Worlds")
            ? words2019
            : name.includes("Europeans")
            ? euro2019
            : ukImages[season]
        }
        alt={name}
      />
      <div className="flex flex-1 flex-col justify-between items-stretch">
        <div className="">
          <div className="px-4 py-1">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-primary text-grey-lighter"
              }`}
            >
              {dayjs(new Date(parseInt(startDate))).format("DD MMM")} -{" "}
              {dayjs(new Date(parseInt(endDate))).format("DD MMM")}
            </span>
          </div>
          <div className="px-4 py-2">
            <div
              className={`font-bold text-lg mb-2 ${expired ? "text-grey" : ""}`}
            >
              {name}
            </div>
            <p
              className={`text-grey-darker font-light ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {organization}
            </p>
            <p
              className={`text-grey-dark font-light pt-2 ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {description} <br />
              <a
                className="no-underline text-grey-darker hover:text-black"
                href={contact}
              >
                {contact}
              </a>
            </p>
          </div>
          <div className="px-4 py-2">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-secondary text-grey-lighter"
              }`}
            >
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

const ParticipantCard = ({ season, event, id, name, country, parts }) => (
  <div className="border border-grey-light p-2 flex flex-col mb-2 hover:bg-grey-lightest">
    <div className="flex pb-2 justify-between">
      <span className="text-base font-bold uppercase text-grey-darker">
        {name}
      </span>
      <span className="text-base font-bold uppercase text-grey-dark">
        {country || "--"}
      </span>
    </div>
    <div className="border-t border-grey-light pt-2 flex">
      <div className="w-3/4 flex flex-wrap align-top">
        {parts.map(({ type, plaque }, j) => (
          <span
            key={`participant-${id}-${name}-${j}`}
            className={`flex-no-grow self-center rounded-full bg-${
              colors[type] || "blue-darker"
            } uppercase px-2 py-1 text-xs text-white font-bold mr-2`}
          >
            {`${type}${plaque ? `|${plaque.country} ${plaque.number}` : ""}`}
          </span>
        ))}
      </div>
      <div className="w-1/4 flex align-top">
        <div className="flex flex-col">
          <Link
            to={`/event/${season}/${event}/participant/${id}`}
            className="inline-block text-center no-underline block uppercase tracking-wide text-sm font-bold py-2 px-4 rounded focus:outline-none w-full mt-1 bg-blue-dark text-grey-lightest hover:bg-blue-darker border-b-4 border-blue-darker hover:border-blue shadow-inner"
          >
            View
          </Link>
          <a
            href={`/event/${season}/${event}/stickers/${id}`}
            className="inline-block text-center no-underline block uppercase tracking-wide text-sm font-bold py-2 px-4 rounded focus:outline-none w-full mt-1 bg-primary text-grey-lightest hover:bg-red-darker border-b-4 border-red-darker hover:border-red shadow-inner"
            target="_blank"
            rel="noopener noreferrer"
          >
            Print Stickers
          </a>
        </div>
      </div>
    </div>
  </div>
);

const ParticipantsCard = ({ participants, id, season, numParticipants }) => (
  <Card className="bg-white p-4">
    <p className="text-grey-dark text-sm py-2">
      This event has{" "}
      {`${numParticipants} participant${numParticipants > 1 ? "s" : ""}`}{" "}
      registered so far.
    </p>
    {participants.map((p, i) => (
      <ParticipantCard
        key={`participant-${id}-${i}`}
        {...p}
        season={season}
        event={id}
      />
    ))}
  </Card>
);

export default ({ match }) => {
  const { season, id } = match.params;
  const { loading, error, data } = useQuery(QueryEvent, {
    variables: { season, id },
  });
  return (
    <Placeholder showMessage={loading || error} message={"Loading event info"}>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="w-full md:w-2/3 px-2">
          <h2 className="text-black font-light my-4">Registered Members</h2>
          {data && data.event && (
            <ParticipantsCard
              numParticipants={data.event.numParticipants}
              participants={data.event.participants.sort(
                (a, b) =>
                  // a.name.localeCompare(b.name)
                  // Sort by sailing name
                  +a.parts.find((p) => p.type === "hull")?.plaque?.number -
                  +b.parts.find((p) => p.type === "hull")?.plaque?.number
              )}
              season={data.event.season}
              id={data.event.id}
            />
          )}
        </div>
        <div className="w-full px-2 md:w-1/3">
          <h2 className="text-black font-light my-4">Event Info</h2>
          {data && data.event && <EventCard {...data.event} />}
        </div>
      </div>
    </Placeholder>
  );
};
