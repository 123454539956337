import React, { useState } from 'react';
import { useForm } from '../../hooks';
import { isNotEmpty, validate } from "../../utils/validator";
import { Button, Alert } from "../ui";
import countries from "../../utils/countries";

export default ({ initialData = {}, onSubmit, lock = false, alert = null }) => {
  const [message, setMessage] = useState([null, null, null]);
  const validation = {
    "name": validate([[isNotEmpty, "Please inform your full name"]]),
    "country": validate([[isNotEmpty, "Please inform your country"]]),
    "homeClub": validate([[isNotEmpty, "Please inform your home club"]])
  };
  const submit = async (values, errors) => {
    if (errors === false) {
      if (onSubmit) {
        try {
          const input = Object.keys(values).reduce((input, prop) => values[prop] ? ({ [prop]: values[prop], ...input }) : input, {});
          await onSubmit({ input });
          setMessage(["Your profile has been updated", null, "success"]);
        } catch (e) {
          setMessage(["Please verify the errors below" ,e.errors.map(e => e.message).join(", "), "error"]);
        }
      }
    } else {
      setMessage(["Please verify the errors below", null, "error"]);
    }
    return false;
  };
  const { name = "", country = "", homeClub = "", isafId = "" } = initialData;
  const { handleSubmit, render } = useForm(submit, validation, { name, country, homeClub, isafId }, [
    {
      title: "Personal Information",
      subtitle: "Let us know who you are",
      fields: [
        ["name", "Full Name*", "text", "p-1 w-full"],
      ]
    },{
      title: "Location",
      subtitle: "Let us know where you are from",
      fields: [
        ["country", "Country*", "select", "p-1 w-full", countries.sort()],
        ["homeClub", "Home Club*", "text", "p-1 w-full"]
      ]
    },{
      title: "ISAF",
      subtitle: "Add your ISAF ID",
      fields: [
        ["isafId", "ISAF ID", "text", "p-1 w-full"],
      ]
    }
  ]);
  return (
    <form className='w-full' onSubmit={handleSubmit}>
      { message[0] ? (<Alert type={message[2]} title={message[0]} message={message[1]} />) : (alert && (<Alert type={alert[2]} title={alert[0]} message={alert[1]} />)) }
      {render}
      <div className='flex justify-end'>
        <Button link to='/' className='mr-2' color="transparent">Cancel</Button>
        <Button type='submit' className='mr-4' disabled={lock}>{lock ? "Processing..." : "Save"}</Button>
      </div>
    </form>
  );
};
