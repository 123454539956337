
import React, { useState, useEffect } from 'react';

export const generateInputField = ([name, label, type, classes, options = null, placeholder = '', onChange = null], formId, values, handleChange, errors = {}) => options === null ? (
  <div key={`form-${formId}-${name}`} className={`w-full px-3 mb-4 md:mb-0 ${classes}`}>
    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor={`${formId}-${name}`}>{label}</label>
    <input 
      className={`appearance-none block w-full bg-grey-lighter border-grey-light text-grey-darker border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${errors[name] ? 'border-red' : ''}`} 
      id={`${formId}-${name}`} 
      type={type}
      name={name}
      placeholder={placeholder}
      value={values[name] || ''}
      onChange={onChange === null ? handleChange : onChange(handleChange)}
    />
    {errors[name] && (<p className="text-red text-xs italic">{errors[name]}</p>)}
  </div>
) : (
  <div key={`form-${formId}-${name}`} className={`w-full px-3 mb-4 md:mb-0 ${classes}`}>
    <label className="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" htmlFor={`${formId}-${name}`}>{label}</label>
    <div className="relative mb-3">
      <select 
        id={`${formId}-${name}`} 
        name={name} 
        onChange={handleChange}
        value={values[name] || ''}
        className={`block appearance-none w-full bg-grey-lighter border border-grey-light text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey ${errors[name] ? 'border-red' : ''}`}>
        { options.map(opt => <option key={`form-${formId}-${name}-option-${Array.isArray(opt) ? opt[0] : opt}`} value={Array.isArray(opt) ? opt[0] : opt}>{ Array.isArray(opt) ? opt[1] : opt }</option>) }
      </select>
      <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
      </div>
    </div>
    {errors[name] && (<p className="text-red text-xs italic">{errors[name]}</p>)}
  </div>
);

const renderSection = (section, formId, values, handleChange, key, errors) => section !== null ? (
  (
    <div key={`form-${formId}-section${key}`} className='flex flex-wrap w-full border-t border-grey-light py-4'>
      <div className='w-full md:w-1/3'>
        <h3 className='tracking-wide text-grey-darker font-normal leading-tight'>{section.title}</h3>
        <h5 className='text-grey-dark font-normal'>{section.subtitle}</h5>
      </div>
      <div className='flex flex-wrap w-full mt-4 md:mt-0 md:w-2/3'>
        {section.fields.map(field => field ? generateInputField(field, formId, values, handleChange, errors) : null)}
      </div>
    </div>
  )
) : null;

const useForm = (callback, validation = {}, defaultValues = {}, renderOptions = null, onChange = null,formId = "fixed-form") => {
  const [values, setValues] = useState(defaultValues);
  const [errors, setErrors] = useState({});

  useEffect(() => setErrors({}), [formId])

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const errors = {};
    Object.keys(validation).forEach(field => {
      if (validation[field]) {
        const valid = validation[field](values[field] || "", values);
        if (valid !== true) {
          errors[field] = valid;
        }
      }
    });
    setErrors(errors);
    return callback(values, Object.keys(errors).length === 0 ? false : errors);
  };

  const handleChange = (event) => { 
    event.persist(); 
    let newValues = {};
    if (event.target.type === "checkbox") {
      newValues =  { ...values, [event.target.name]: event.target.checked };
    } else {
      newValues = { ...values, [event.target.name]: event.target.value };
    }
    if (errors[event.target.name]) {
      delete errors[event.target.name];
      setErrors(errors);
    }
    if (onChange) {
      newValues = onChange(newValues, event.target.name, values[event.target.name] || null, event.target.value);
    }
    setValues(newValues);
  };
  const render = renderOptions ? Array.isArray(renderOptions[0]) ? (
    <>
      {renderOptions.map(field => generateInputField(field, formId, values, handleChange, errors))}
    </>
  ) : (
    <>
      {renderOptions.map((section, i) => renderSection(typeof section === "function" ? section(values) : section, formId, values, handleChange, `${formId}-section-${i}`, errors))}
    </>
  ) : (null);

  return {
    handleChange,
    handleSubmit,
    values,
    render,
    errors
  };
};

export default useForm;