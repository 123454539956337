import React from 'react';
import { Link } from "react-router-dom";

const colors = {
  "blue": "bg-blue-dark text-grey-lightest hover:bg-blue-darker border-b-4 border-blue-darker hover:border-blue shadow-inner",
  "primary": "bg-primary text-grey-lightest hover:bg-red-darker border-b-4 border-red-darker hover:border-red shadow-inner",
  "transparent": "bg-transparent text-grey-darker hover:bg-grey-lightest border-b-4 border-transparent hover:border-grey-light",
  "facebook": "bg-facebook text-grey-lightest hover:bg-facebook-dark border-b-4 border-blue-darker hover:border-facebook-light shadow-inner",
}

export default ({ children, className, link, to = null, color = "blue", disabled, ...props }) => link ? (
  <Link {...props} to={to} className={`inline-block text-center no-underline block uppercase tracking-wide text-sm font-bold py-2 px-4 rounded focus:outline-none ${colors[color]} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
    {children}
  </Link>
  ) : (
  <button {...props} className={`uppercase text-sm font-bold py-2 px-4 rounded focus:outline-none ${colors[color]} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}>
    {children}
  </button>
);