import React, { useState } from "react";
import { useForm } from "../../hooks";
import { isNotEmpty, validate } from "../../utils/validator";
import { Button, Alert } from "../ui";
import ModalConfirmAction from "../ModalConfirmAction";

export default ({ onSubmit, alert = null, lock = false }) => {
  const [message, setMessage] = useState([null, null, null]);
  const [showConfirmMessage, setShowConfirmMessage] = useState(false)
  const validation = {
    "reason": validate([[isNotEmpty, "Please inform the reason to deactivate the equipment"]])
  };

  const submit = async (values, errors) => {
    setShowConfirmMessage(false);
    if (errors === false) {
      if (onSubmit) {
        try {
          const input = Object.keys(values).reduce((input, prop) => values[prop] ? ({ [prop]: values[prop], ...input }) : input, {});
          await onSubmit({ ...input });
        } catch (e) {
          setMessage([e.errors.map(e => e.message).join(", "), "error"]);
        }
      }
    } else {
      setMessage(["Please verify the errors below", null, "error"]);
    }
    return false;
  };

  const { handleSubmit, render } = useForm(submit, validation, { }, [
    {
      title: "Deactivate Equipment",
      subtitle: "Let us know the reason you are deactivating this equipment",
      fields: [
        ["reason", "Reason", "text", "p-1 w-full"],
      ]
    }
  ]);
  return (
    <form className='w-full' onSubmit={(e) => { e.preventDefault(); setShowConfirmMessage(true); }}>
      { message[0] ? (<Alert type={message[2]} title={message[0]} message={message[1]} />) : (alert && (<Alert type={alert[2]} title={alert[0]} message={alert[1]} />)) }
      {render}
      <div className='flex justify-end'>
        <Button color="primary" type='submit' className='mr-4' disabled={lock}>{lock ? "Processing..." : "Deactivate"}</Button>
      </div>
      <ModalConfirmAction
        isOpen={showConfirmMessage}
        cancelAction={() => setShowConfirmMessage(false)}
        confirmAction={handleSubmit}
        onClose={null}
        message="Are you sure you want to remove this piece of equipment?"
      />
    </form>
  );
};