import React, { useState } from "react";
import { useForm } from "../../hooks";
import { isNotEmpty, validate } from "../../utils/validator";
import { Button, Alert } from "../ui";
import countries from "../../utils/countries";

export default ({
  initialData = {},
  manufacturers = [],
  types = [],
  fixedType = null,
  onSubmit,
  alert = null,
  lock = false,
}) => {
  const [message, setMessage] = useState([null, null, null]);
  const typesList = types
    .filter((t) => t.visible)
    .map((t) => [t.name, t.name.charAt(0).toUpperCase() + t.name.slice(1)]);
  const validation = {
    name: validate([[isNotEmpty, "Please inform the equipment name"]]),
    manufacturer: validate([
      [isNotEmpty, "Please inform the equipment manufacturer"],
    ]),
    type: validate([[isNotEmpty, "Please inform the equipment type"]]),
  };
  if (fixedType === "hull") {
    validation["plaqueCountry"] = validate([
      [isNotEmpty, "Please inform the plaque country"],
    ]);
    validation["plaqueNumber"] = validate([
      [isNotEmpty, "Please inform the plaque number"],
    ]);
  }

  const submit = async (values, errors) => {
    if (errors === false) {
      if (onSubmit) {
        try {
          if (values.model && values.model === "Not listed") {
            values.model = values.modelText || false;
          }
          if (values.manufacturer === "Homebuild or Not listed") {
            values.model = values.modelText || false;
          }
          values.modelText = false;
          const input = Object.keys(values).reduce(
            (input, prop) =>
              values[prop] ? { [prop]: values[prop], ...input } : input,
            {}
          );
          if (input.plaqueCountry || input.plaqueNumber) {
            input.plaque = {
              country: input.plaqueCountry,
              number: input.plaqueNumber,
            };
            delete input["plaqueCountry"];
            delete input["plaqueNumber"];
          }
          await onSubmit({ input });
        } catch (e) {
          setMessage([e.errors.map((e) => e.message).join(", "), "error"]);
        }
      }
    } else {
      setMessage(["Please verify the errors below", null, "error"]);
    }
    return false;
  };

  const {
    type = fixedType ? fixedType : false,
    manufacturer = "",
    model = "",
    name = "",
    year = "",
    plaque = null,
    length = "",
    span = "",
    notes = "",
  } = initialData;
  const plaqueFields =
    plaque === null
      ? {}
      : { plaqueCountry: plaque.country, plaqueNumber: plaque.number };

  const { handleSubmit, render, values } = useForm(
    submit,
    validation,
    {
      type,
      manufacturer,
      name,
      model,
      year,
      ...plaqueFields,
      length,
      span,
      notes,
    },
    [
      (values) => {
        const manufacList = values.type
          ? manufacturers
              .filter((m) => m.parts.map((p) => p.type).includes(values.type))
              .map((m) => m.name)
          : [];
        let modelsList = null;
        try {
          if (values.manufacturer) {
            const filterManufac = manufacturers.filter(
              (m) => m.name === values.manufacturer
            );
            if (filterManufac.length === 1) {
              modelsList = filterManufac[0].parts
                .filter((p) => p.type === values.type)[0]
                .models.map((m) => m.name);
            }
          }
        } catch (e) {
          modelsList = null;
        }
        const fields = [
          [
            "manufacturer",
            "Manufacturer",
            "select",
            "p-1 w-full",
            [
              ["", "Select the manufacturer"],
              ...manufacList,
              "Homebuild or Not listed",
            ],
          ],
          modelsList
            ? [
                "model",
                "Model",
                "select",
                "p-1 w-full",
                [["", "Select the model"], ...modelsList, "Not listed"],
              ]
            : null,
          (values.model && values.model === "Not listed") || !modelsList
            ? ["modelText", "Model", "text", "p-1 w-full"]
            : null,
        ];
        return {
          title: "Manufacturer",
          subtitle:
            "Enter the manufacturer and model of your equipment. For masts, if you have different bottom mast and top masts, please select “not listed”, and indicate in the field the top and bottom masts you are adding (for example: Towpro TB1 and Tv2)",
          fields: fixedType
            ? fields
            : [
                [
                  "type",
                  "Type",
                  "select",
                  "p-1 w-full",
                  [["", "Select the type of equipment"], ...typesList],
                ],
                ...fields,
              ],
        };
      },
      {
        title: "Equipment Information",
        subtitle:
          "Here you can give your part a name to help identify it e.g. Stiff Mast or Spare mast. This will appear on your printer stickers which you will need to attach to equipment so a memorable and clear name for yourself and measurers is helpful",
        fields: [
          ["name", "Name", "text", "p-1 w-full"],
          [
            "year",
            "Year of Manufacture",
            "select",
            "p-1 w-full",
            [
              "Unknown",
              ...Array.apply(null, { length: 15 }).map(
                (_, i) => `${new Date().getFullYear() - i}`
              ),
            ],
          ],
        ],
      },
      (values) => {
        return values.type && values.type === "hull"
          ? {
              title: "Plaque",
              subtitle: "Inform your boat registration",
              fields: [
                [
                  "plaqueCountry",
                  "Country",
                  "select",
                  "p-1 md:w-1/2",
                  [["", "Select the country"], ...countries],
                ],
                ["plaqueNumber", "Number", "text", "p-1 md:w-1/2"],
              ],
            }
          : null;
      },
      (values) => {
        return values.type
          ? {
              title: "Measurements / Part dimensions",
              subtitle:
                "You can add in simple measurements here to help identify your part to measurers as part of the check in and spot checking process. For Masts you are required to enter total length, for foils you are required to enter the span and depth of the foil from the bulb to trailing point of the flap. You can also enter notes here for any modifications you have made to standard dimensions for example.",
              fields: [
                ["length", "Length", "text", "p-1 w-full"],
                ["span", "Span", "text", "p-1 w-full"],
                ["notes", "Notes", "text", "p-1 w-full"],
              ].filter((f) =>
                types
                  .filter((t) => t.name === values.type)[0]
                  .fields.includes(f[0])
              ),
            }
          : null;
      },
    ],
    (newValues, property, oldValue, newValue) => {
      if (property === "type") {
        newValues.manufacturer = false;
        newValues.model = false;
      } else if (property === "manufacturer") {
        newValues.model = false;
      }
      if (property === "model") {
        try {
          const model = manufacturers
            .filter((m) => m.name === values.manufacturer)[0]
            .parts.filter((p) => p.type === values.type)[0]
            .models.filter((m) => m.name === newValue)[0];
          if (model.length) {
            newValues.length = model.length;
          }
          if (model.span) {
            newValues.span = model.span;
          }
          if (model.notes) {
            newValues.notes = model.notes;
          }
        } catch (e) {
          //Ignore
        }
        if (newValues.model !== "Not listed") {
          delete newValues["modelText"];
        }
      }
      return newValues;
    }
  );
  return (
    <form className="w-full" onSubmit={handleSubmit}>
      {message[0] ? (
        <Alert type={message[2]} title={message[0]} message={message[1]} />
      ) : (
        alert && <Alert type={alert[2]} title={alert[0]} message={alert[1]} />
      )}
      {render}
      <div className="flex justify-end">
        <Button link to="/" className="mr-2" color="transparent">
          Cancel
        </Button>
        <Button type="submit" className="mr-4" disabled={lock}>
          {lock ? "Processing..." : "Save"}
        </Button>
      </div>
    </form>
  );
};
