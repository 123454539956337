import React from 'react';
import { Button } from "./ui";

export default ({ showMessage, message, error, children, action = null }) => showMessage ? (
  <div className={`w-full py-10 mb-2 text-lg border-2 border-grey-dark border-dashed rounded text-grey-dark flex flex-col justify-center items-center ${error ? 'border-red text-red' : ''}`}>
      {error && error.errors ? error.errors.map(e => e.message).join(', ') : message}
      {action && (Array.isArray(action) ? (<div className='w-full flex justify-center pt-4'>
        <Button to={action[0]} link>{action[1]}</Button>
      </div>) : action )}
  </div>
) : (<>{children}</>);