import React from 'react';
import { Button, Modal } from "./ui";

export default ({ message, isOpen, onClose, confirmAction, cancelAction}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='flex flex-col w-full justify-center'>
        <p className="py-4 text-center">{ message }</p>
        <div className='flex w-full justify-center'>
          <Button className="mr-2" color="transparent" onClick={cancelAction}>Cancel</Button>
          <Button color="blue" onClick={confirmAction}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
};