import React from "react";
import { useQuery } from "graphql-hooks";
import dayjs from "dayjs";
import { EventParticipant as QueryEvent } from "../graphql/queries";
import Placeholder from "../components/Placeholder";
import Card from "../components/Card";
import { Button } from "../components/ui";
import { FileViewer } from "./Equipments/Certificate";

import euro2019 from "../images/events/euro2019.jpg";
import euro2022 from "../images/events/euro2022.jpg";
import words2019 from "../images/events/world2019.jpg";
import uk2019 from "../images/events/uk2019.jpg";
import uk2020 from "../images/events/uk2020.jpg";

const ukImages = {
  2019: uk2019,
  2020: uk2020,
};

const eventImages = {
  GSpufppFXyPAhPgRyjW8Q: euro2022,
};

const colors = {
  hull: "green",
  sail: "yellow-dark",
  mainfoil: "purple",
  rudderfoil: "pink-dark",
};

const EventCard = ({
  id,
  name,
  startDate,
  image,
  endDate,
  expired,
  organization,
  season,
  location,
  description,
  contact,
}) => (
  <Card className="bg-white">
    <div className="">
      <img
        className="w-full rounded-t"
        src={
          eventImages[id]
            ? eventImages[id]
            : image
            ? image
            : name.includes("Worlds")
            ? words2019
            : name.includes("Europeans")
            ? euro2019
            : ukImages[season]
        }
        alt={name}
      />
      <div className="flex flex-1 flex-col justify-between items-stretch">
        <div className="">
          <div className="px-4 py-1">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-primary text-grey-lighter"
              }`}
            >
              {dayjs(new Date(parseInt(startDate))).format("DD MMM")} -{" "}
              {dayjs(new Date(parseInt(endDate))).format("DD MMM")}
            </span>
          </div>
          <div className="px-4 py-2">
            <div
              className={`font-bold text-lg mb-2 ${expired ? "text-grey" : ""}`}
            >
              {name}
            </div>
            <p
              className={`text-grey-darker font-light ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {organization}
            </p>
            <p
              className={`text-grey-dark font-light pt-2 ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {description} <br />
              <a
                className="no-underline text-grey-darker hover:text-black"
                href={contact}
              >
                {contact}
              </a>
            </p>
          </div>
          <div className="px-4 py-2">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-secondary text-grey-lighter"
              }`}
            >
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

const EquipmentCard = ({ season, event, id, equipment }) => (
  <div className="border border-grey-light p-2 flex flex-col mb-2 hover:bg-grey-lightest">
    <div className="flex pb-2 justify-between">
      <div>
        <span
          className={`flex-no-grow self-center rounded-full bg-${
            colors[equipment.type] || "blue-darker"
          } uppercase px-2 py-1 text-xs text-white font-bold mr-2`}
        >
          {`${equipment.type}${
            equipment.plaque
              ? `|${equipment.plaque.country} ${equipment.plaque.number}`
              : ""
          }`}
        </span>
        <span className="text-base font-bold uppercase text-grey-darker">
          {equipment.name}
        </span>
      </div>
      <span className="text-base font-bold uppercase text-grey-dark">
        {equipment.manufacturer} - {equipment.model || "NO MODEL"}
      </span>
    </div>
    {equipment.hasCertificate ? (
      equipment.certificateLink.map((e, i) => (
        <FileViewer key={`${id}-file${i}`} equipment={e} />
      ))
    ) : (
      <div className="flex flex-1 p-4 md:px-0 justify-center border-t border-grey-light text-grey-dark">
        The sailor didn't submit the certificate for this equipment
      </div>
    )}
    {equipment.hasInsurance ? (
      <FileViewer equipment={equipment.insuranceLink} />
    ) : (
      <div className="flex flex-1 p-4 md:px-0 justify-center border-t border-grey-light text-grey-dark">
        The sailor didn't submit the insurance for this equipment
      </div>
    )}
  </div>
);

const ParticipantCard = ({ participant, id, season }) => (
  <>
    <h2 className="text-black font-light my-4">
      {participant.name} ({participant.country || "--"})
    </h2>
    {participant.parts.map((p, i) => (
      <EquipmentCard
        key={`equipment-${id}-${i}`}
        equipment={p}
        season={season}
        event={id}
      />
    ))}
    {/* <Card className='bg-white p-4'> */}
    {/* <p className='text-grey-dark text-sm py-2'>This event has {`${participants.length} participant${participants.length > 1 ? 's' : ''}`} registered so far.</p> */}
    {/* { participants.map((p, i) => <ParticipantCard key={`participant-${id}-${i}`} {...p} season={season} event={id} />)} */}
    {/* </Card> */}
  </>
);

export default ({ match }) => {
  const { season, id, participantId } = match.params;
  const { loading, error, data } = useQuery(QueryEvent, {
    variables: { season, id, participantId },
  });
  return (
    <Placeholder showMessage={loading || error} message={"Loading event info"}>
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 px-2">
          {data &&
            data.event &&
            data.event.participants &&
            data.event.participants.length > 0 && (
              <ParticipantCard
                participant={data.event.participants[0]}
                season={data.event.season}
                id={data.event.id}
              />
            )}
        </div>
        <div className="w-full px-2 md:w-1/3">
          {data && data.event && (
            <Button
              link
              to={`/event/${data.event.season}/${data.event.id}`}
              color="blue"
              className="w-full mt-4"
            >
              Return to Event Page
            </Button>
          )}
          <h2 className="text-black font-light my-4">Event Info</h2>
          {data && data.event && <EventCard {...data.event} />}
        </div>
      </div>
    </Placeholder>
  );
};
