import React, { useEffect } from "react";
import Card from "../../components/Card";
import EquipmentCheckForm from "../../components/forms/EquipmentCheckForm";
import { EventParticipantEquipment } from "../../graphql/queries";
import { useManualQuery } from "graphql-hooks";
import { FileViewer } from "../Equipments/Certificate";

const colors = {
  hull: "green",
  sail: "yellow-dark",
  mainfoil: "purple",
  rudderfoil: "pink-dark"
};

export default ({ match: { params } }) => {
  const equipmentCode = decodeURIComponent(params.code);
  const [getEquipment, { data, error }] = useManualQuery(
    EventParticipantEquipment
  );

  const submit = async values => {
    if (values.eventCode && values.eventCode !== "") {
      localStorage.setItem("eventCode", values.eventCode);
      localStorage.setItem(
        "eventCodeExpireAt",
        new Date(new Date().getTime() + 60)
      );
    }
    await getEquipment({ variables: values });
  };

  useEffect(() => {
    if (
      localStorage.getItem("eventCode") &&
      localStorage.getItem("eventCodeExpireAt") &&
      localStorage.getItem("eventCode") < new Date()
    ) {
      submit({ equipmentCode, eventCode: localStorage.getItem("eventCode") });
    }
  }, []);

  if (data && data.eventParticipantEquipment) {
    const { event, participant, equipment } = data.eventParticipantEquipment;
    return (
      <>
        <h2 className="text-black font-light my-4">
          {participant.name} ({participant.country}){" "}
          {participant.homeClub ? `(${participant.homeClub})` : ""}
        </h2>
        <Card className="p-4 mb-4">
          <div className="flex flex-1 p-4 md:px-0 justify-center text-grey-dark">
            The following equipment was checked-in for the event: {event.name} (
            {event.location})
          </div>
          <div className="flex flex-1 p-4 md:px-0 justify-center">
            <span
              className={`flex-no-grow self-center rounded-full bg-${colors[
                equipment.type
              ] ||
                "blue-darker"} uppercase px-2 py-1 text-base text-white font-bold mr-2`}
            >
              {`${equipment.type}${
                equipment.plaque
                  ? `|${equipment.plaque.country} ${equipment.plaque.number}`
                  : ""
              }`}
            </span>
          </div>
          <div className="flex flex-1 p-4 md:px-0 justify-center">
            Equipment Name: {equipment.name} <br />
            Manufacturer: {equipment.manufacturer} <br />
            Model: {equipment.model} <br />
            Status: {equipment.status}
          </div>
        </Card>

        <h2 className="text-black font-light my-4">Certificate</h2>
        {equipment.hasCertificate ? (
          Array.isArray(equipment.certificateLink) ? (
            equipment.certificateLink.map(e => <FileViewer equipment={e} />)
          ) : (
            <FileViewer
              file="certificate"
              equipment={equipment.certificateLink}
            />
          )
        ) : (
          <Card className="p-4 mb-4">
            <div className="flex flex-1 p-4 md:px-0 justify-center">
              The sailor didn't submit the certificate for this equipment
            </div>
          </Card>
        )}

        <h2 className="text-black font-light my-4">Insurance</h2>
        {equipment.hasInsurance ? (
          <FileViewer file="insurance" equipment={equipment.insuranceLink} />
        ) : (
          <Card className="p-4 mb-4">
            <div className="flex flex-1 p-4 md:px-0 justify-center">
              The sailor didn't submit the insurance for this equipment
            </div>
          </Card>
        )}
      </>
    );
  }

  return (
    <>
      <h2 className="text-black font-light my-4">User Registration View</h2>
      <Card className="p-4 mb-4">
        <div className="flex flex-1 p-4 md:px-0 justify-center">
          <EquipmentCheckForm
            initialData={{ eventCode: "", equipmentCode }}
            onSubmit={submit}
            alert={
              error && error.graphQLErrors && error.graphQLErrors.length > 0
                ? [error.graphQLErrors.pop().message, null, "error"]
                : null
            }
          />
        </div>
      </Card>
    </>
  );
};
