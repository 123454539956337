export const Profile = `query Profile {
  profile {
    sub
    name
    email
    homeClub
    country
    isafId
  }
}`;

export const ListManufacturers = `query ListManufacturers {
  manufacturers {
    id
    name
    parts {
      type
      models {
        name
        span
        length
        notes
      }
    }
  }
}`;

export const Event = `query Event($season: String!, $id: String!) {
  event(season: $season, id: $id) {
    name
    season
    id
    location
    description
    organization
    contact
    endDate
    startDate
    expired
    userRegistration
    image
  }
}`;

export const EventManagement = `query EventManagement($season: String!, $id: String!, $participantId: String) {
  event(season: $season, id: $id, participantId: $participantId) {
    name
    season
    id
    location
    description
    organization
    contact
    endDate
    startDate
    expired
    numParticipants
    image
    participants {
      id
      name
      country
      parts {
        name
        type
        qrcode
        plaque {
          country
          number
        }
      }
    }
  }
}`;

export const EventParticipant = `query EventParticipant($season: String!, $id: String!, $participantId: String) {
  event(season: $season, id: $id, participantId: $participantId) {
    name
    season
    id
    location
    description
    organization
    contact
    endDate
    startDate
    expired
    image
    participants {
      id
      name
      country
      parts {
        name
        type
        manufacturer
        model
        hasInsurance
        hasCertificate
        status
        plaque {
          country
          number
        }
        certificateLink {
          link
          format
        }
        insuranceLink {
          link
          format
        }
      }
    }
  }
}`;

export const Events = `query Events($season: String!) {
  events(season: $season) {
    name
    season
    id
    location
    description
    organization
    contact
    endDate
    startDate
    expired
    userRegistration
    userIsManager
    image
  }
}`;

export const Equipments = `query Equipments($type: String!) {
  equipments(type: $type) {
    typeId
    name
    manufacturer
    model
    type
    plaque {
      country
      number
    }
    year
  }
}`;

export const Equipment = `query Equipments($type: String!, $id: String!) {
  equipment(type: $type, id: $id) {
    typeId
    name
    manufacturer
    model
    type
    plaque {
      country
      number
    }
    year
    hasCertificate
    hasInsurance
    length
    span
    notes
  }
}`;

export const EquipmentCertificate = `query Equipments($type: String!, $id: String!) {
  equipment(type: $type, id: $id) {
    name
    certificateLink {
      link
      format
    }
  }
}`;

export const EquipmentInsurance = `query Equipments($type: String!, $id: String!) {
  equipment(type: $type, id: $id) {
    name
    insuranceLink {
      link
      format
    }
  }
}`;

export const EventParticipantEquipment = `query EventParticipantEquipment($eventCode: String!, $equipmentCode: String!) {
  eventParticipantEquipment(eventCode: $eventCode, equipmentCode: $equipmentCode) {
    event {
      name
      location
    }
    participant {
      name
      homeClub
      country
    }
    equipment {
      name
      manufacturer
      model
      hasInsurance
      hasCertificate
      status
      type
      plaque {
        country
        number
      }
      certificateLink {
        link
        format
      }
      insuranceLink {
        link
        format
      }
    }
  }
}`;
