import React from "react";
import { Link, Redirect } from "react-router-dom";
import dayjs from "dayjs";
import TabCard from "../components/TabCard";
import Placeholder from "../components/Placeholder";
import { useQuery } from "graphql-hooks";
import {
  Events as QueryEvents,
  Equipments as QueryEquipments,
} from "../graphql/queries";
import { Button, Alert } from "../components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons/faPlusSquare";

import euro2022 from "../images/events/euro2022.jpg";
import euro2019 from "../images/events/euro2019.jpg";
import words2019 from "../images/events/world2019.jpg";
import world2021 from "../images/events/world2021.jpg";
import world2022 from "../images/events/world2022.jpg";
import uk2019 from "../images/events/uk2019.jpg";
import uk2020 from "../images/events/uk2020.jpg";

const ukImages = {
  2019: uk2019,
  2020: uk2020,
};

const eventImages = {
  GSpufppFXyPAhPgRyjW8Q: euro2022,
};

const ListAdd = ({ to, text }) => (
  <Link
    to={to}
    className="flex w-1/2 overflow-hidden md:max-w-1/5 md:w-1/5 no-underline"
  >
    <div
      style={{ minHeight: "120px" }}
      className="rounded m-2 flex-grow-1 flex flex-col w-full shadow bg-grey-lighter border-4 border-dashed text-grey-dark text-base uppercase font-bold border-grey-light hover:border-solid hover:border-secondary hover:bg-grey-light hover:text-secondary hover:shadow-inner"
    >
      <div className="flex flex-1 flex-col justify-center items-center">
        <FontAwesomeIcon icon={faPlusSquare} size="2x" className="opacity-75" />
        <span className="pt-2">{text}</span>
      </div>
    </div>
  </Link>
);

const Equipment = ({
  name,
  typeId,
  manufacturer,
  model,
  type,
  plaque,
  year,
}) => (
  <div className="flex-initial flex w-1/2 overflow-hidden md:max-w-1/5 md:w-1/5">
    <div
      className="rounded m-2 flex-grow-1 flex flex-col w-full"
      style={{ boxShadow: "0px 2px 3px #C1C7D0" }}
    >
      <div className="flex flex-1 flex-col justify-between items-stretch">
        <div className="">
          <div className="px-2 py-1">
            <span
              className={`inline-block uppercase rounded-full px-3 py-1 text-xs font-semibold mr-2 text-grey-lighter ${
                type === "hull" ? "bg-red" : "bg-primary"
              }`}
            >
              {type}
            </span>
          </div>
          <div className="px-2 py-2">
            <div
              className={`font-bold text-base mb-2 ${
                type === "hull" ? "" : ""
              }`}
            >
              {name}
            </div>
            <p
              className={`text-grey-darker font-light text-sm ${
                type === "hull" ? "" : ""
              }`}
            >
              <strong>{manufacturer}</strong>
              {model && (
                <>
                  <br />
                  {model}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="p-2">
          <Button
            link
            to={`/equipment/${typeId.split("|").join("/")}`}
            className="w-full"
          >
            Edit
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const Equipments = ({ type, emptyAction = null, emptyMessage }) => {
  const { loading, error, data } = useQuery(QueryEquipments, {
    variables: { type },
  });
  const { equipments = [] } = data || { equipments: [] };
  return (
    <Placeholder
      showMessage={loading || error}
      error={error}
      message={
        error
          ? "An error happened and we couldn't load the equipment"
          : "Loading equipment"
      }
    >
      {equipments.length === 0 ? (
        <Placeholder showMessage message={emptyMessage} action={emptyAction} />
      ) : (
        <div className="flex w-full flex-wrap">
          {equipments.map((e, i) => (
            <Equipment key={`equipment-${type}-${i}`} {...e} />
          ))}
          <ListAdd
            to={`/equipment/${type === "hull" ? "boat" : "parts"}/add`}
            text={`${type === "hull" ? "Add Boat" : "Add Equipment"}`}
          />
        </div>
      )}
    </Placeholder>
  );
};

const Event = ({
  name,
  description,
  image,
  location,
  startDate,
  endDate,
  organization,
  season,
  id,
  expired,
  userRegistration,
  userIsManager,
}) => (
  <div className="flex-initial flex max-w-sm overflow-hidden md:max-w-1/3">
    <div
      className="rounded bg-white m-2 flex-grow-1 flex flex-col w-full"
      style={{ boxShadow: "0px 2px 3px #C1C7D0" }}
    >
      <img
        className="w-full rounded-t"
        src={
          eventImages[id]
            ? eventImages[id]
            : image
            ? image
            : name.includes("Worlds")
            ? words2019
            : name.includes("Europeans")
            ? euro2019
            : ukImages[season]
        }
        alt={name}
      />
      <div className="flex flex-1 flex-col justify-between items-stretch">
        <div className="">
          <div className="px-4 py-2">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-primary text-grey-lighter"
              }`}
            >
              {dayjs(new Date(parseInt(startDate))).format("DD MMM")} -{" "}
              {dayjs(new Date(parseInt(endDate))).format("DD MMM")}
            </span>
          </div>
          <div className="px-4 py-4">
            <div
              className={`font-bold text-lg mb-2 ${expired ? "text-grey" : ""}`}
            >
              {name}
            </div>
            <p
              className={`text-grey-darker font-light ${
                expired ? "text-grey-light" : ""
              }`}
            >
              {organization}
            </p>
          </div>
          <div className="px-4 py-2">
            <span
              className={`inline-block rounded-full px-3 py-1 text-sm font-semibold mr-2 ${
                expired
                  ? "bg-grey text-grey-lighter"
                  : "bg-secondary text-grey-lighter"
              }`}
            >
              {location}
            </span>
          </div>
        </div>
        {!expired && !userIsManager && (
          <div className="p-2">
            <Button
              link
              to={`/checkin/${season}/${id}`}
              className={`w-full leading-loose`}
              color={userRegistration === null ? "blue" : "primary"}
            >
              {userRegistration === null ? "Check-in Equipment" : "Modify"}
            </Button>
          </div>
        )}
        {userIsManager && (
          <div className="p-2">
            <Button
              link
              to={`/event/${season}/${id}`}
              className={`w-full leading-loose`}
              color={"transparent"}
            >
              Manage Event
            </Button>
          </div>
        )}
      </div>
    </div>
  </div>
);

const Events = ({ season }) => {
  const { loading, error, data } = useQuery(QueryEvents, {
    variables: { season },
  });
  const { events = [] } = data || { events: [] };
  return (
    <Placeholder
      showMessage={loading || error}
      error={error}
      message={
        error
          ? "An error happened and we couldn't load the events"
          : "Loading events"
      }
    >
      {events.length === 0 ? (
        <Placeholder
          showMessage
          message="We don't have any upcoming event for this season"
        />
      ) : (
        <div className="flex w-full flex-wrap">
          {events.map((e, i) => (
            <Event key={`events-${season}-${i}`} {...e} />
          ))}
        </div>
      )}
    </Placeholder>
  );
};

export default () => {
  return (
    <div>
      <div>
        <h2 className="text-black font-light my-4">Boats & Equipment</h2>
        <TabCard
          tabs={[
            {
              key: "boats",
              title: "Boats",
              content: (
                <Equipments
                  type="hull"
                  emptyMessage={"You don't have any boat registered"}
                  emptyAction={<Redirect to="/welcome" />}
                />
              ),
            },
            {
              key: "parts",
              title: "Parts",
              content: (
                <Equipments
                  type="parts"
                  emptyMessage={"You don't have any part registered"}
                  emptyAction={["/equipment/parts/add", <>Add a equipment</>]}
                />
              ),
            },
          ]}
        />
      </div>
      <div className="my-6">
        <h2 className="text-black font-light my-4">Events</h2>
        <div className="my-2">
          <Alert message="To comply with IMCA event equipment restrictions and to speed up the measurement process for IMCA measurers you are required to check-in your equipment you intend to use in competition at the event along with any measurement certificates attached to the equipment. This will then be reviewed and approved by the Measurement team at the event." />
        </div>
        <TabCard
          tabs={[
            new Date().getFullYear(),
            new Date().getFullYear() - 1,
            new Date().getFullYear() - 2,
          ].map((y) => ({
            key: `events${y}`,
            title: y,
            content: <Events season={`${y}`} />,
          }))}
        />
      </div>
    </div>
  );
};
