import auth0 from "auth0-js";
import { createBrowserHistory } from "history";
import { AUTH_CONFIG } from "./config";

const history = createBrowserHistory({
  forceRefresh: true,
});

export default class Auth {
  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: `${process.env.REACT_APP_URL}/callback`,
    responseType: "token id_token",
  });

  constructor() {
    // this.auth0.crossOriginVerification();
    this.signUp = this.signUp.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.facebookLogin = this.facebookLogin.bind(this);
    this.universalLogin = this.universalLogin.bind(this);
    this.recoveryPassword = this.recoveryPassword.bind(this);
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        { realm: AUTH_CONFIG.dbConnectionName, username, password },
        (err, authResult) => {
          if (err) {
            reject(err.description);
            return;
          }
          history.push("/");
          resolve();
        }
      );
    });
  }

  facebookLogin() {
    this.auth0.authorize({ connection: "facebook" });
  }

  universalLogin() {
    this.auth0.authorize({ connection: "MothClass-Authentication" });
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    // navigate to the home route
    return true;
  }

  handleAuthentication() {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        history.push("/");
        return true;
      } else if (err) {
        history.push("/");
        console.log(err);
        return false;
      }
    });
  }

  signUp(email, password) {
    return new Promise((resolve, reject) => {
      this.auth0.redirect.signupAndLogin(
        { connection: AUTH_CONFIG.dbConnectionName, email, password },
        (err) => {
          if (err) {
            reject(err.description || err.name);
            return;
          }
          history.push("/");
          resolve();
        }
      );
    });
  }

  recoveryPassword(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        { connection: AUTH_CONFIG.dbConnectionName, email },
        (err) => {
          if (err) {
            reject(err.description);
            return;
          }
          resolve();
        }
      );
    });
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    history.push("/");
  }

  getToken() {
    if (this.isAuthenticated()) {
      return localStorage.getItem("id_token");
    }
    return false;
  }
}
