import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from 'graphql-hooks';
import { ListManufacturers as QueryListManufacturers, Equipment as QueryEquipment } from '../graphql/queries';
import { CreateEquipment, UpdateEquipment, GenerateEquipmentCertificateUploadLink, GenerateEquipmentInsuranceUploadLink, RemoveCertificate, RemoveInsurance, DeactivateEquipment } from '../graphql/mutations';
import Placeholder from "../components/Placeholder";
import Card from '../components/Card';
import TabCard from '../components/TabCard';
import EquipmentForm from "../components/forms/EquipmentForm";
import DeactivateEquipmentForm from "../components/forms/DeactivateEquipmentForm";
import { Notification, Button, Modal } from "../components/ui";
import { faBell } from '@fortawesome/free-regular-svg-icons/faBell';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons/faFileAlt';
import UploadCertificate from "./Equipments/UploadCertificate";

const types = [
  {
    name: "hull",
    fields: ["notes"],
    visible: false
  },{
    name: "mast",
    fields: ["length", "notes"],
    visible: true
  },{
    name: "mainfoil",
    fields: ["length", "span", "notes"],
    visible: true
  },{
    name: "sail",
    fields: ["notes"],
    visible: true
  },{
    name: "rudderfoil",
    fields: ["length", "span", "notes"],
    visible: true
  }
];


const DeactivateEquipmentCard = ({ type, id }) => {
  const [deactivateEquipment, { loading, data }] = useMutation(DeactivateEquipment);
  const submit = useCallback(async variables => {
    await deactivateEquipment({ variables: { ...variables, type, id }});
  }, [type, id]);
  if (data && data.deactivateEquipment) {
    return (<Redirect to={`/`} />)
  }
  return (
    <Card className="p-4 mb-4">
      <DeactivateEquipmentForm onSubmit={submit} lock={loading} />
    </Card>
  )
}

const InsuranceCard = ({ type, id, equipment, toggleUpload, refetch }) => {
  const [removeInsurance, { loading: loadingRemoveMutation }] = useMutation(RemoveInsurance);
  const remove = useCallback(async () => {
    await removeInsurance({ variables: { type, id } });
    if (refetch) refetch();
  }, [refetch, type, id]);
  return (
    <Card className="p-4 mb-4">
      <div className='flex flex-1 p-4 md:px-0 justify-center'>
        {!equipment.hasInsurance ? 
          (<Button onClick={() => toggleUpload("insurance")}>Upload Insurance</Button>) : 
          (<>
            <Button color="transparent" link to={`/equipment/${type}/${id}/insurance`} className='mr-2 self-end'>View Insurance</Button>
            <Button color="primary" disabled={loadingRemoveMutation} onClick={remove} className='self-end'>Remove Insurance</Button>
          </>)}
      </div>
    </Card>
  )
}

const EditEquipment = ({ type, id }) => {
  const { loading: loadingManufacturers, error: errorManufacturers, data: dataManufacturers } = useQuery(QueryListManufacturers);
  const { loading, error, data, refetch } = useQuery(QueryEquipment, { variables: { type, id }});
  const [updateEquipment, { loading: loadingMutation, error: errorMutation, data: dataMutation }] = useMutation(UpdateEquipment);
  const [removeCertificate, { loading: loadingRemoveMutation }] = useMutation(RemoveCertificate);
  const submit = useCallback(async ({ input: { type: _, ...fields }}) => {
    await updateEquipment({ variables: { type, id, input: { ...fields } } });
    if (refetch) refetch();
  }, [refetch, type, id]);

  const remove = useCallback(async () => {
    await removeCertificate({ variables: { type, id } });
    if (refetch) refetch();
  }, [refetch, type, id]);

  const { equipment } = data || { equipment: {} };
  const [upload, setUpload] = useState(false);
  const toggleUpload = (mode = "certificate") => setUpload(upload === false ? mode : false);
  const hasCertificate = ({ type }) => type === "hull" || type === "sail";
  const hasInsurance = ({ type }) => type === "hull";

  if (error || errorManufacturers) {
    return (
    <Placeholder 
      error
      message="An error happened and we couldn't retrieve the equipment"
    />);
  }

  return (
    <>
      <div className='flex flex-col md:flex-row justify-between'>
        <div className='flew-grow-1'>
          <h2 className='text-black font-light my-4'>{ equipment.name || "" }</h2>
          {hasCertificate(equipment) && !equipment.hasCertificate && 
            (<Notification icon={faBell} color="red" message={`Your ${type === 'hull' ? 'boat' : type} doesn't have a certificate registered.`} />)
          }
        </div>
        {hasCertificate(equipment) && 
          (<div className='flex flex-1 p-4 md:px-0 justify-end'>
            {!equipment.hasCertificate ? 
              (<Button className='self-end' onClick={() => toggleUpload("certificate")}>Upload Certificate</Button>) : 
              (<>
                <Button color="transparent" link to={`/equipment/${type}/${id}/certificate`} className='mr-2 self-end'>View Certificate</Button>
                <Button color="primary" disabled={loadingRemoveMutation} onClick={remove} className='self-end'>Remove Certificate</Button>
              </>)}
          </div>)
        }
      </div>
      <Modal isOpen={upload !== false} onClose={toggleUpload}>
        <UploadCertificate 
          {...{ type, id }} 
          mutation={upload === "certificate" ? GenerateEquipmentCertificateUploadLink : GenerateEquipmentInsuranceUploadLink}
          title={upload === "certificate" ? "Upload Certificate" : "Upload Insurance"} 
          field={upload === "certificate" ? "generateEquipmentCertificateUploadLink" : "generateEquipmentInsuranceUploadLink"}
          onSuccess={() => { refetch(); toggleUpload(); }} 
        />
      </Modal>
      <Card className="p-4">
        <Placeholder showMessage={(loading && loadingManufacturers && !data) || error} message={"Loading options"}>
          {data && data.equipment && (
            <EquipmentForm 
              lock={loadingMutation}
              initialData={data.equipment} 
              manufacturers={dataManufacturers ? dataManufacturers.manufacturers : []} 
              types={types} 
              fixedType={data.equipment.type} 
              alert={
                errorMutation ? ["An error happened!", "We couldn't save your changes", "success"] : 
                dataMutation ? ["Your changes were saved!", "", "success"] : null}
              onSubmit={submit}
            />)
          }
        </Placeholder>
      </Card>
      {!(loading && loadingManufacturers && !data) && !error && hasInsurance(equipment) && (
        <>
          <h2 className='text-black font-light my-4'>Insurance</h2>
          <InsuranceCard {...{ type, id, equipment, toggleUpload, refetch }} />
        </>
      )}
      {!(loading && loadingManufacturers && !data) && !error && (
        <>
          <h2 className='text-black font-light my-4'>Deactivate</h2>
          <DeactivateEquipmentCard {...{ type, id }} />
        </>
      )}
    </>
  );
};

const AddEquipment = ({ hull }) => {
  const { loading: loadingManufacturers, error: errorManufacturers, data: dataManufacturers } = useQuery(QueryListManufacturers);
  const [createEquipment, { loading: loadingMutation, error: errorMutation, data: dataMutation }] = useMutation(CreateEquipment);
  if (dataMutation && dataMutation.createEquipment) {
    const { typeId } = dataMutation.createEquipment;
    const [type, id] = typeId.split("|");
    return (<Redirect to={`/equipment/${type}/${id}`} />)
  }
  return (
    <TabCard 
      tabs={[
        { 
          key: "equipment", 
          title: "Equipment", 
          content: (
            <Placeholder 
              showMessage={loadingManufacturers || errorManufacturers} 
              message={"Loading equipment options..."}
            >
              <div className='flex flex-col'>
                <Notification 
                  color="blue-dark"
                  icon={faFileAlt} 
                  message={"If you are adding a hull or a sail you will be able to upload your certificates after saving your equipment details"} 
                />
                <EquipmentForm 
                  initialData={{}} 
                  manufacturers={dataManufacturers ? dataManufacturers.manufacturers : []} 
                  types={types} 
                  fixedType={hull ? "hull" : ""} 
                  alert={errorMutation ? ["An error happened!", "We couldn't save your changes", "error"] : null}
                  onSubmit={(variables) => createEquipment({ variables })}
                  lock={loadingMutation}
                />
              </div>
            </Placeholder>) 
        }
      ]}
    />
  ); 
}


export default ({ match }) => {
  const { type, id } = match.params;
  if (id === "add") {
    return (<AddEquipment hull={type === "boat"} />)
  }
  return (<EditEquipment {...{ type, id }} />)
}
