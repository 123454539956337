import React, { useState } from 'react'

export default ({ tabs }) => {
  const [active, setActive] = useState(tabs[0].key);
  const { content } = tabs.filter(t => t.key === active)[0];
  return (
    <div className='w-full shadow bg-grey-lightest'>
      <div className='flex'>
        <ul className='flex list-reset uppercase text-sm'>
          {tabs.map(({ key, title }) => (
            <li key={key} onClick={() => setActive(key)} className={`px-6 py-4 border-t-4 border-${active === key ? 'primary font-bold' : 'transparent'} hover:border-secondary hover:bg-grey-light cursor-pointer`}>{title}</li>
          ))}
        </ul>
      </div>
      <div className='flex p-2'>
        { content }
      </div>
    </div>
  )
}
