import React from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  withRouter,
} from "react-router-dom";
import { GraphQLClient, ClientContext, useQuery } from "graphql-hooks";
import { Profile as QueryProfile } from "./graphql/queries";
import Auth0 from "./Auth0";
import "./css/tailwind.css";
import "./App.css";

import LoadingLayout from "./layouts/LoadingLayout";
import MainLayout from "./layouts/MainLayout";
import Terms from "./pages/Terms";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Equipment from "./pages/Equipment";
import EquipmentCertificate from "./pages/Equipments/Certificate";
import EquipmentPublicView from "./pages/Equipments/PublicView";
import EquipmentManage from "./pages/Equipments/Manage";
import EventRegistration from "./pages/EventRegistration";
import EventManagement from "./pages/EventManagement";
import EventParticipant from "./pages/EventParticipant";
import EventStickers from "./pages/EventStickers";
import Faq from "./pages/FAQ";
import WelcomePage from "./pages/WelcomePage";

const Auth = new Auth0();

const handleAuthentication = (nextState, replace) => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    Auth.handleAuthentication();
  }
};

const UnauthorizedRoot = (props) => {
  const graphQlClient = new GraphQLClient({
    url: `${process.env.REACT_APP_API_URL}/graphql`,
  });
  return (
    <ClientContext.Provider value={graphQlClient}>
      <Switch>
        <Route
          path="/terms"
          render={(routeProps) => (
            <Terms content="terms" {...routeProps} {...props} />
          )}
        />
        <Route
          path="/privacy"
          render={(routeProps) => (
            <Terms content="privacy" {...routeProps} {...props} />
          )}
        />
        <Route
          path="/login"
          render={(routeProps) => (
            <Login mode="signin" {...routeProps} {...props} />
          )}
        />
        <Route
          path="/create-account"
          render={(routeProps) => (
            <Login mode="signup" {...routeProps} {...props} />
          )}
        />
        <Route
          path="/recovery-password"
          render={(routeProps) => (
            <Login mode="recovery-password" {...routeProps} {...props} />
          )}
        />
        <MainLayout
          exact
          path="/eq/:code"
          page={EquipmentPublicView}
          userProfile={null}
        />
        <Route
          path="/callback"
          render={(props) => {
            handleAuthentication(props);
            return <LoadingLayout />;
          }}
        />
        <Redirect to="/login" />
      </Switch>
    </ClientContext.Provider>
  );
};

const Main = withRouter(({ location, match, history, ...rest }) => {
  const { loading, error, data, refetch } = useQuery(QueryProfile);
  if (loading && !data) return <LoadingLayout />;
  if (error) return <Redirect to="/login" />;
  const { profile } = data;
  if (profile === null && location.pathname !== "/profile") {
    return <Redirect to="/profile" />;
  }
  return (
    <Switch>
      <MainLayout exact path="/" page={Home} userProfile={profile} {...rest} />
      <MainLayout
        exact
        path="/profile"
        page={Profile}
        userProfile={profile}
        onUpdate={refetch}
        {...rest}
      />
      <MainLayout
        exact
        path="/equipment/manage"
        page={EquipmentManage}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/equipment/:type/:id/certificate"
        page={EquipmentCertificate}
        file="certificate"
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/equipment/:type/:id/insurance"
        page={EquipmentCertificate}
        file="insurance"
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/equipment/:type/:id"
        page={Equipment}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/checkin/:season/:id"
        page={EventRegistration}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/event/:season/:id"
        page={EventManagement}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/event/:season/:id/participant/:participantId"
        page={EventParticipant}
        userProfile={profile}
        {...rest}
      />
      <Route
        exact
        path="/event/:season/:id/stickers"
        render={(props) => <EventStickers {...props} />}
      />
      <Route
        exact
        path="/event/:season/:id/stickers/:participantId"
        render={(props) => <EventStickers {...props} />}
      />
      <MainLayout
        exact
        path="/faq"
        page={Faq}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        exact
        path="/welcome"
        page={WelcomePage}
        userProfile={profile}
        {...rest}
      />
      <MainLayout
        path="/eq/:code"
        page={EquipmentPublicView}
        userProfile={null}
      />
      <Redirect to="/" />
    </Switch>
  );
});

const AuthorizedRoot = (props) => {
  const { logout } = Auth;
  const graphQlClient = new GraphQLClient({
    url: `${process.env.REACT_APP_API_URL}/graphql`,
    headers: {
      Authorization: `Bearer ${Auth.getToken()}`,
    },
    onError: ({ operation, result }) => {
      console.log(result);
      if (result.graphQLErrors) {
        if (
          result.graphQLErrors
            .map((e) => e.extensions.code)
            .includes("UNAUTHENTICATED")
        ) {
          logout();
          return false;
        }
      }
    },
  });

  return (
    <ClientContext.Provider value={graphQlClient}>
      <Main {...props} />
    </ClientContext.Provider>
  );
};

const App = () => {
  const {
    isAuthenticated,
    signUp,
    login,
    logout,
    facebookLogin,
    universalLogin,
    recoveryPassword,
  } = Auth;
  return (
    <BrowserRouter>
      {isAuthenticated() ? (
        <AuthorizedRoot {...{ logout }} />
      ) : (
        <UnauthorizedRoot
          {...{
            signUp,
            login,
            facebookLogin,
            universalLogin,
            recoveryPassword,
          }}
        />
      )}
    </BrowserRouter>
  );
};

export default App;
