import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'graphql-hooks';
import Placeholder from "../../components/Placeholder";
import { Button } from "../../components/ui";

export default ({ type, id, title, mutation, field, onSuccess }) => {
  const [getUploadUrl] = useMutation(mutation);
  const [files, setFiles] = useState(null);
  const [uploadLinks, setUploadLinks] = useState([]);
  const onUploadReady = useCallback(async () => {
    let count = 0;
    let success = true;
    for (const uploadLink of uploadLinks) {
      if (uploadLink && uploadLink[field]) {
        const uploadForm = JSON.parse(uploadLink[field].url);
        const formData = new FormData();
        Object.entries(uploadForm.fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append('Content-Type', files[count].type);
        formData.append("file", files[count]);
        const result = await fetch(uploadForm.url, {
          method: 'POST',
          body: formData
        });
        if (result.status !== 204) {
          success = false;
        }
        count++;
      }
      if (success) {
        if (onSuccess) onSuccess();
      }
    }
  }, [uploadLinks]);

  const onDrop = useCallback(async acceptedFiles => {
    const updateUrls = await Promise.all(acceptedFiles.map((file, i) => getUploadUrl({ variables: { type, id, filetype: file.type, number: i+1 }})));
    setFiles(acceptedFiles);
    setUploadLinks(updateUrls.map(url => url.data));
  }, [type, id]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({ 
    onDrop, 
    multiple: true, 
    maxSize: 10485760,
    accept: 'image/jpeg, image/png, image/gif, application/pdf'
  });

  return (
    <Placeholder showMessage={false} message={"Processing..."}>
      <div className='flex flex-col'>
        <h2 className='font-light'>{ title }</h2>
        <div className={`w-full py-10 my-2 text-lg border-2 border-grey border-dashed rounded text-grey bg-grey-lighter flex flex-col justify-center items-center`} {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              files !== null ? 
              <p>{ files.map(f => f.name).join(", ") }</p> :
              <p>Drop your file here, or click to select the file</p>
          }
        </div>
      </div>
      {files !== null && (<div className='w-full flex justify-center'>
          <Button onClick={onUploadReady} color="primary">Upload</Button>
      </div>)}
    </Placeholder>
  );
}
