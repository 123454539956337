import React, { useState } from "react";
import { useQuery } from "graphql-hooks";
import { Redirect } from "react-router-dom";
import PDF from "react-pdf-js";
import {
  EquipmentCertificate as QueryEquipmentCertificate,
  EquipmentInsurance as QueryEquipmentInsurance
} from "../../graphql/queries";
import Placeholder from "../../components/Placeholder";
import Card from "../../components/Card";
import TabCard from "../../components/TabCard";
import { Button } from "../../components/ui";

const PdfPage = ({ file, page, onLoad }) => (
  <div className="w-full">
    <PDF file={file} onDocumentComplete={onLoad} page={page} className="" />
  </div>
);

const PdfViewer = ({ file }) => {
  const [pages, setPages] = useState(false);
  return (
    <TabCard
      tabs={new Array(pages[1]).fill(0).map((_, i) => ({
        key: `pdf-page-${i}`,
        title: `${i + 1}`,
        content: (
          <PdfPage file={file} page={i + 1} onLoad={p => setPages([1, p])} />
        )
      }))}
    />
  );
};

const FileViewer = ({ equipment }) => {
  return (
    <>
      {equipment.format.toLowerCase() === "pdf" ? (
        <PdfViewer file={equipment.link} />
      ) : (
        <Card className="p-4">
          <img src={equipment.link} alt="Certificate" className="w-full" />
        </Card>
      )}
    </>
  );
};

const ViewEquipmentCertificate = ({ type, id, file }) => {
  const { loading, error, data } = useQuery(
    file === "certificate"
      ? QueryEquipmentCertificate
      : QueryEquipmentInsurance,
    { variables: { type, id } }
  );
  const { equipment } = data || { equipment: {} };

  if (error) {
    return (
      <Placeholder
        error
        message="An error happened and we couldn't retrieve the equipment"
      />
    );
  }
  if (loading) {
    return <Placeholder error message={`Loading equipment ${file}`} />;
  }
  if (data && data.equipment && data.equipment[`${file}Link`] === null) {
    return <Redirect to={`/equipment/${type}/${id}`} />;
  }

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <div className="flew-1">
          <h2 className="text-black font-light my-4 w-full">
            {equipment.name || ""}
          </h2>
        </div>
        <div className="flex flex-1 p-4 md:px-0 justify-end">
          <Button
            color="transparent"
            link
            to={`/equipment/${type}/${id}`}
            className="self-end"
          >
            Back to Equipment Page
          </Button>
        </div>
      </div>
      {data &&
      data.equipment &&
      data.equipment[`${file}Link`] &&
      Array.isArray(data.equipment[`${file}Link`]) ? (
        <div>
          {data.equipment[`${file}Link`].map(eq => (
            <FileViewer equipment={eq} />
          ))}
        </div>
      ) : (
        <FileViewer equipment={data.equipment[`${file}Link`]} />
      )}
    </>
  );
};

export default ({ match, file }) => {
  const { type, id } = match.params;
  return <ViewEquipmentCertificate {...{ type, id, file }} />;
};

export { FileViewer };
