import React from 'react';
import { useForm } from '../../hooks';
import { generateInputField } from '../../hooks/useForm';
import { isNotEmpty, isInList, validate } from "../../utils/validator";
import { Button } from "../ui";

export default ({ onSubmit, lock = false, alert = null, manufacturer }) => {
  const validation = {
    "name": validate([[isNotEmpty, "Name is required"]]),
    "type": validate([[isInList(["hull", "mast", "sail", "mainfoil", "hudderfoil"]), "Type is required"]]),
  };
  const submit = async (values, errors) => {
    if (errors === false) {
      if (onSubmit) {
        try {
          const variables = Object.keys(values).reduce((input, prop) => values[prop] ? ({ [prop]: values[prop], ...input }) : input, {});
          await onSubmit(variables);
        } catch (e) {
        }
      }
    }
    return false;
  };
  const { handleSubmit, handleChange, errors, values } = useForm(submit, validation, { type: "hull"  });
  return (
    <form className='w-full' onSubmit={handleSubmit}>
      <div className='flex flex-wrap'>
        {generateInputField(["name", "Name*", "text", "p-1 md:w-1/2"], `add-equipment-${manufacturer}`,values, handleChange, errors)}
        {generateInputField(["notes", "Notes", "text", "p-1 md:w-1/2"], `add-equipment-${manufacturer}`,values, handleChange, errors)}
        {generateInputField(["type", "Type*", "select", "p-1 md:w-1/3", ["hull", "mast", "sail", "mainfoil", "hudderfoil"]], `add-equipment-${manufacturer}`,values, handleChange, errors)}
        {generateInputField(["length", "Length", "text", "p-1 md:w-1/3"], `add-equipment-${manufacturer}`,values, handleChange, errors)}
        {generateInputField(["span", "Span", "text", "p-1 md:w-1/3"], `add-equipment-${manufacturer}`,values, handleChange, errors)}
      </div>
      <div className='flex justify-end'>
        <Button type='submit' className='mr-4' disabled={lock}>{lock ? "Processing..." : "Submit"}</Button>
      </div>
    </form>
  );
};